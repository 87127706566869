import React from 'react';

import Home from '../modules/pages/home/Home';

const home = () => {
  return (
    <Home
      seo={{
        description: 'Nunca foi tão fácil entender seu cliente! Integre hoje e crie suas análises em minutos.',
        lang: 'pt',
      }}
    />
  );
};

export default home;
